<div class="card column filter-component" style="width: 23rem">
	<div class="flex-row header">
		<div>
			<h6 class="card-title titleCardStyle">Showing results for</h6>
		</div>
		<span (click)="clearAll()" class="clearAll" style="cursor: pointer">Clear</span>
	</div>
	<mat-accordion>
		<!-- Speciality component -->
		<mat-expansion-panel #panelSpec hideToggle="true" (opened)="triggerAutoFocus('#includeSpecialityInput')">
			<mat-expansion-panel-header
				[ngClass]="panelSpec.expanded ? 'expanded' : ''"
				[collapsedHeight]="'70px'"
				[expandedHeight]="'70px'"
			>
				<mat-panel-title>
					<div>Specialty</div>
					<div class="header-info" *ngIf="includedSpecialities.length == 0">Include Specialty</div>
					<div class="selected" *ngIf="includedSpecialities.length > 0">
						<mat-chip-list #includeSpecialityList aria-label="Included speciality">
							<mat-basic-chip *ngIf="includedSpecialities?.length > 0" [ngClass]="'selected'">
								{{ includedSpecialities[0] }}
							</mat-basic-chip>
							<span *ngIf="includedSpecialities?.length > 1" class="additional-selection">
								+ {{ includedSpecialities.length - 1 }} more
							</span>
						</mat-chip-list>
					</div>
				</mat-panel-title>
				<mat-panel-description>
					<mat-icon class="matrl-plus">{{ panelSpec.expanded ? 'remove' : 'add' }}</mat-icon>
				</mat-panel-description>
			</mat-expansion-panel-header>
			<span class="form-label">Include Specialty</span>
			<mat-form-field>
				<mat-chip-list #includeSpecialityList aria-label="Include speciality input">
					<mat-basic-chip
						*ngFor="let spec of includedSpecialities"
						[selectable]="selectable"
						[removable]="removable"
						(removed)="removeIncludeSpeciality(spec)"
					>
						{{ spec }}
						<mat-icon matChipRemove>close</mat-icon>
					</mat-basic-chip>
					<input
						#includeSpecialityInput
						id="includeSpecialityInput"
						aria-label="Include Speciality"
						matInput
						[formControl]="includeSpecialityControl"
						[matAutocomplete]="includeSpecAuto"
						floatPlaceholder="never"
						[matChipInputFor]="includeSpecialityList"
						[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
					/>
				</mat-chip-list>
				<mat-autocomplete
					#includeSpecAuto="matAutocomplete"
					(optionSelected)="selectedIncludeSpeciality($event)"
					class="app-autocomplete"
				>
					<mat-option *ngFor="let spec of filteredSpeciality" [value]="spec"> {{ spec }} </mat-option>
				</mat-autocomplete>
			</mat-form-field>
			<mat-chip-list aria-label="Terms selection" #includedTerms>
				<mat-basic-chip
					*ngFor="let st of suggestionTerms"
					class="suggested"
					[selectable]="true"
					value="st"
					[selected]="st"
				>
					<span class="chip-body">
						<span style="flex: 1"> {{ st }}</span>
						<span class="chip-add" (click)="termsSelected(st)"> +</span>
						<span class="chip-remove" (click)="termsSelectedEx(st)">- </span></span
					>
				</mat-basic-chip>
			</mat-chip-list>
			<span class="form-label">Exclude Specialty</span>
			<mat-form-field>
				<mat-chip-list #excludeSpecialityList aria-label="Exclude speciality">
					<mat-basic-chip
						*ngFor="let spec of excludedSpecialities"
						[selectable]="selectable"
						[removable]="removable"
						(removed)="removeExcludeSpeciality(spec)"
					>
						{{ spec }}
						<mat-icon matChipRemove>close</mat-icon>
					</mat-basic-chip>
					<input
						#excludeSpecialityInput
						matInput
						[matChipInputFor]="excludeSpecialityList"
						[formControl]="excludeSpecialityControl"
						[matAutocomplete]="excludeSpecAuto"
						floatPlaceholder="never"
						[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
					/>
				</mat-chip-list>
				<mat-autocomplete
					#excludeSpecAuto="matAutocomplete"
					(optionSelected)="selectedExcludeSpeciality($event)"
					class="app-autocomplete"
				>
					<mat-option *ngFor="let spec of filteredSpecialityEx" [value]="spec"> {{ spec }} </mat-option>
				</mat-autocomplete>
			</mat-form-field>
		</mat-expansion-panel>
		<!-- Speciality component end -->

		<!-- Hospital Name components starts -->
		<app-expand-item
			class="dialog-flex-container"
			title="Hospital"
			placeholder="Hospital name, Firm type, Hospital classification"
			[isPaid]="isPaid"
			[selectedData]="hospitalExpandData"
		>
			<app-auto-complete-drop-drow
				title="Hospital Name"
				[(list)]="filteredHospitals"
				(onChange)="getHospitalName($event)"
				(selectedDataChange)="selectedHospitalName($event)"
				[itemName]="'hospitalName'"
				[itemValue]="'hospitalName'"
				[(selectedData)]="hospitalNames"
			></app-auto-complete-drop-drow>

			<app-signle-select-drop-down
				title="Select Firm Type"
				[(list)]="hospitalFirmType"
				[(selectedData)]="filterData.firmtype"
				(onItemSelect)="omitChange()"
			></app-signle-select-drop-down>

			<app-select-drop-down
				title="Select Hospital Classification"
				[list]="hospitalClassification"
				(onItemSelect)="omitChange()"
				[(selectedData)]="filterData.classification"
			></app-select-drop-down>
		</app-expand-item>
		<!-- Hospital Name component end-->
		<!-- NPI number component -->
		<mat-expansion-panel
			#panelNpi
			hideToggle="true"
			[disabled]="isPaid"
			(opened)="triggerAutoFocus('#npiNumberInput')"
		>
			<mat-expansion-panel-header
				[ngClass]="panelNpi.expanded ? 'expanded' : ''"
				[collapsedHeight]="'70px'"
				[expandedHeight]="'70px'"
			>
				<mat-panel-title>
					<div><mat-icon class="filter-title" *ngIf="isPaid">lock</mat-icon>NPI Number</div>
					<div class="header-info" *ngIf="npiNumbers.length == 0">NPI number search</div>
					<div class="selected" *ngIf="npiNumbers.length > 0">
						<mat-chip-list #addedHospitals aria-label="NPI numbers">
							<mat-basic-chip *ngIf="npiNumbers?.length > 0" [ngClass]="'selected'">
								{{ npiNumbers[0] }}
							</mat-basic-chip>
							<span *ngIf="npiNumbers?.length > 1" class="additional-selection">
								+ {{ npiNumbers.length - 1 }} more
							</span>
						</mat-chip-list>
					</div>
				</mat-panel-title>
				<mat-panel-description>
					<mat-icon class="matrl-plus">{{ panelNpi.expanded ? 'remove' : 'add' }}</mat-icon>
				</mat-panel-description>
			</mat-expansion-panel-header>
			<mat-form-field>
				<mat-chip-list #npiNumberChipList aria-label="NPI number input">
					<mat-basic-chip
						*ngFor="let npi of npiNumbers"
						[selectable]="selectable"
						[removable]="removable"
						(removed)="removeNPI(npi)"
					>
						{{ npi }}
						<mat-icon matChipRemove>close</mat-icon>
					</mat-basic-chip>
					<input
						#npiNumberInput
						id="npiNumberInput"
						aria-label="Number"
						matInput
						[formControl]="npiNumberControl"
						[matAutocomplete]="npiNumberAuto"
						floatPlaceholder="never"
						[matChipInputFor]="npiNumberChipList"
						[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
						numbersOnly
					/>
				</mat-chip-list>

				<mat-autocomplete #npiNumberAuto="matAutocomplete" class="app-autocomplete">
					<mat-option *ngFor="let npi of npiNumberList" [value]="npi" (click)="onNPISelect(npi)">
						<mat-checkbox [checked]="npiNumbers.includes(npi)"></mat-checkbox>
						{{ npi }}
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>
		</mat-expansion-panel>
		<!-- NPI number component End-->
		<!-- Location component -->
		<div class="location">
			<app-individual-location-filter
				[countryList]="[]"
				(locationValueChanged)="storeFilterData(); omitChange()"
				[(selectedStatesFromFilter)]="filterData.stateList"
				[(selectedCitiesFromFilter)]="filterData.cityList"
				[(selectedZipFromFilter)]="filterData.zipcode"
				[disabled]="isPaid"
				[isZipCode]="true"
			>
			</app-individual-location-filter>
		</div>
		<!-- Location component ends -->

		<div class="more-filter-container">
			<button
				class="more-filter-button"
				(click)="openMoreFilter()"
				[class.diasble-more-filter]="isPaid"
				[disabled]="isPaid"
			>
				More Filters <i class="fa fa-external-link ml-2" aria-hidden="true" *ngIf="!isPaid"> </i>
				<mat-icon style="margin-left: 6px; margin-top: 3px" class="filter-title" *ngIf="isPaid">lock</mat-icon>
			</button>
			<!-- <div class="more-filter-applied-circle" >
				{{ appliedAdvanceFiltersLength }}
			</div> -->
		</div>
	</mat-accordion>
</div>

<div class="upgrade-card" (click)="requestPricing()" *ngIf="isPaid">
	<div>
		<div class="blt-background">
			<mat-icon class="mt-ico">bolt</mat-icon>
		</div>
	</div>
	<div style="padding-left: 10px">
		<div class="upgrd-to-unlock">Request to unlock more filters</div>
		<div class="improve-search">Improve your search result with additional filters</div>
	</div>
</div>

<!-- More filter starts -->
<ng-template #filterDialog let-data>
	<div class="mat-dialog-title">
		<div class="modal-header d-flex justify-content-between">
			<div class="dialog-title d-flex align-items-center">
				<span>Filters</span>

				<div class="ml-2">
					<button class="main-clear-more-filter" (click)="clearFilter('clearAll'); moreFilterAccordian.closeAll()">
						<span class="clear-more-filter-text">Clear</span>
					</button>
				</div>
			</div>

			<div class="dialog-action-container d-flex align-items-center">
				<div class="record-container">
					<!-- loader -->
					<div *ngIf="recordCountLoader | async; else recordCount" class="py-2 mr-2">
						<div class="bars"></div>
					</div>
					<!-- loader ends -->

					<!-- Record count starts -->
					<ng-template #recordCount>
						<span class="record-count"> {{ moreFilterRecordCount.value | numberWithCommas }} Records</span>
					</ng-template>
					<!-- Record count ends -->
				</div>

				<button
					class="ml-2 dialog-action-button"
					[disabled]="countLoader"
					[class.disable-action-btn]="countLoader"
					(click)="applyMoreFilters()"
				>
					Apply Filter
				</button>

				<button class="ml-2 dialog-close-button d-flex">
					<mat-icon class="close-dialog-icon" (click)="closeDialogBox('mainDialog')">close</mat-icon>
				</button>
			</div>
		</div>
	</div>
	<mat-dialog-content>
		<mat-accordion #moreFilterAccordian="matAccordion" multi="false">
			<div class="dialog-flex">
				<!-- Speciality component -->
				<div class="dialog-flex-item">
					<div class="expansion-body">
						<mat-expansion-panel
							#panelSpec
							hideToggle="true"
							(opened)="triggerAutoFocus('#includeSpecialityInput')"
						>
							<mat-expansion-panel-header
								[ngClass]="panelSpec.expanded ? 'expanded' : ''"
								[collapsedHeight]="'70px'"
								[expandedHeight]="'70px'"
							>
								<mat-panel-title>
									<div class="d-flex align-items-center">
										Specialty
										<div
											class="ml-2 clear-more-filter"
											*ngIf="
												tempDialogFilters.specialityIncluded.length > 0 || tempDialogFilters.specialityExcluded.length > 0
											"
										>
											<button (click)="clearFilter('speciality'); $event.stopPropagation()">
												<mat-icon class="clear-more-filter-icon">close</mat-icon>
												<span class="clear-more-filter-text">
													{{ tempDialogFilters.specialityIncluded.length + tempDialogFilters.specialityExcluded.length }}
												</span>
											</button>
										</div>
									</div>
								</mat-panel-title>
								<mat-panel-description>
									<mat-icon class="matrl-plus">{{ panelSpec.expanded ? 'remove' : 'add' }}</mat-icon>
								</mat-panel-description>
							</mat-expansion-panel-header>

							<div class="dialog-expansion-content">
								<span class="form-label">Include Specialty</span>
								<mat-form-field>
									<mat-chip-list #includeSpecialityList aria-label="Include speciality input">
										<mat-basic-chip
											*ngFor="let spec of tempDialogFilters.specialityIncluded"
											[selectable]="selectable"
											[removable]="removable"
											(removed)="removeDialogFilter('speciality', spec, 'included')"
										>
											{{ spec }}
											<mat-icon matChipRemove>close</mat-icon>
										</mat-basic-chip>
										<input
											#d_includedSpeciality
											id="includeSpecialityInput"
											aria-label="Include Speciality"
											matInput
											placeholder=""
											[formControl]="includeSpecialityControl"
											[matAutocomplete]="includeSpecAuto"
											floatPlaceholder="never"
											[matChipInputFor]="includeSpecialityList"
											[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
										/>
									</mat-chip-list>
									<mat-autocomplete
										#includeSpecAuto="matAutocomplete"
										(optionSelected)="
											selectDialogFilter('speciality', $event, 'included'); d_includedSpeciality.value = ''
										"
										class="app-autocomplete"
									>
										<mat-option *ngFor="let spec of filteredSpeciality" [value]="spec"> {{ spec }} </mat-option>
									</mat-autocomplete>
								</mat-form-field>
								<mat-chip-list aria-label="Terms selection" #includedTerms>
									<mat-basic-chip
										*ngFor="let st of suggestionTerms"
										class="suggested"
										[selectable]="true"
										value="st"
										[selected]="st"
									>
										<span class="chip-body" (click)="termsSelected(st)">
											<span style="flex: 1"> {{ st }}</span>
											<span class="chip-add"> +</span>
										</span>
									</mat-basic-chip>
								</mat-chip-list>
								<span class="form-label">Exclude Specialty</span>
								<mat-form-field>
									<mat-chip-list #excludeSpecialityList aria-label="Exclude speciality">
										<mat-basic-chip
											*ngFor="let spec of tempDialogFilters.specialityExcluded"
											[selectable]="selectable"
											[removable]="removable"
											(removed)="removeDialogFilter('speciality', spec, 'excluded')"
										>
											{{ spec }}
											<mat-icon matChipRemove>close</mat-icon>
										</mat-basic-chip>
										<input
											id="d_exludedSpeciality"
											#d_exludedSpeciality
											matInput
											[matChipInputFor]="excludeSpecialityList"
											[formControl]="excludeSpecialityControl"
											[matAutocomplete]="excludeSpecAuto"
											floatPlaceholder="never"
											[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
										/>
									</mat-chip-list>
									<mat-autocomplete
										#excludeSpecAuto="matAutocomplete"
										(optionSelected)="
											selectDialogFilter('speciality', $event, 'excluded'); d_exludedSpeciality.value = ''
										"
										class="app-autocomplete"
									>
										<mat-option *ngFor="let spec of filteredSpecialityEx" [value]="spec"> {{ spec }} </mat-option>
									</mat-autocomplete>
								</mat-form-field>
							</div>
						</mat-expansion-panel>
						<div
							class="second-header d-flex"
							*ngIf="
								(tempDialogFilters.specialityExcluded.length > 0 ||
									tempDialogFilters.specialityIncluded.length > 0) &&
								!panelSpec.expanded
							"
						>
							<mat-chip-list #includeSpecialityList>
								<ng-container *ngIf="tempDialogFilters.specialityIncluded.length > 0">
									<mat-basic-chip
										*ngFor="let speciality of tempDialogFilters.specialityIncluded"
										[removable]="removable"
										(removed)="removeDialogFilter('speciality', speciality, 'included')"
									>
										{{ speciality }}
										<mat-icon matChipRemove>close</mat-icon>
									</mat-basic-chip>
								</ng-container>

								<ng-container *ngIf="tempDialogFilters.specialityExcluded.length > 0">
									<mat-basic-chip
										*ngFor="let speciality of tempDialogFilters.specialityExcluded"
										[removable]="removable"
										(removed)="removeDialogFilter('speciality', speciality, 'excluded')"
									>
										{{ speciality }}
										<mat-icon matChipRemove>close</mat-icon>
									</mat-basic-chip>
								</ng-container>
							</mat-chip-list>
						</div>
					</div>
				</div>
				<!-- Speciality component end -->

				<!-- Classification Speciality component -->
				<div class="dialog-flex-item">
					<div class="expansion-body">
						<mat-expansion-panel
							#c_specPanel
							hideToggle="true"
							(opened)="triggerAutoFocus('#includeSpecialityInput')"
						>
							<mat-expansion-panel-header
								[ngClass]="c_specPanel.expanded ? 'expanded' : ''"
								[collapsedHeight]="'70px'"
								[expandedHeight]="'70px'"
							>
								<mat-panel-title>
									<div class="d-flex align-items-center">
										Specialty Classification
										<div class="ml-2 clear-more-filter" *ngIf="tempDialogFilters.specialityGroup != ''">
											<button (click)="clearFilter('classificationSpeciality'); $event.stopPropagation()">
												<mat-icon class="clear-more-filter-icon">close</mat-icon>
												<span class="clear-more-filter-text">
													{{ tempDialogFilters.speciality.length + tempDialogFilters.subSpeciality.length + 1 }}
												</span>
											</button>
										</div>
									</div>
								</mat-panel-title>
								<mat-panel-description>
									<mat-icon class="matrl-plus">{{ c_specPanel.expanded ? 'remove' : 'add' }}</mat-icon>
								</mat-panel-description>
							</mat-expansion-panel-header>

							<div class="dialog-expansion-content">
								<span class="form-label">Specialty Group</span>
								<mat-form-field>
									<mat-chip-list #c_childSpeciality>
										<mat-basic-chip
											*ngIf="tempDialogFilters.specialityGroup != ''"
											[selectable]="selectable"
											[removable]="removable"
											(removed)="
												removeDialogFilter('classificationSpeciality', tempDialogFilters.specialityGroup, 'specGroup')
											"
										>
											{{ tempDialogFilters.specialityGroup }}
											<mat-icon matChipRemove>close</mat-icon>
										</mat-basic-chip>
										<input
											id="d_exludedSpeciality"
											#d_exludedSpeciality
											matInput
											[matChipInputFor]="c_childSpeciality"
											[formControl]="c_specialityControl"
											[matAutocomplete]="cSpecAuto"
											floatPlaceholder="never"
											style="cursor: pointer !important"
											[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
										/>
									</mat-chip-list>
									<mat-autocomplete #cSpecAuto="matAutocomplete" class="app-autocomplete">
										<mat-option
											*ngFor="let spec of c_specialityGroup"
											[value]="spec"
											(click)="selectDialogFilter('classificationSpeciality', spec, 'specGroup')"
										>
											{{ spec.specialityType }} ({{ spec.physicianCount | numberWithCommas }})
										</mat-option>
									</mat-autocomplete>
								</mat-form-field>

								<span class="form-label">Specialty</span>
								<mat-form-field>
									<mat-chip-list #c_childSpeciality>
										<mat-basic-chip
											*ngFor="let spec of tempDialogFilters.speciality"
											[selectable]="selectable"
											[removable]="removable"
											(removed)="removeDialogFilter('classificationSpeciality', spec, 'speciality')"
										>
											{{ spec.speciality }}
											<mat-icon matChipRemove>close</mat-icon>
										</mat-basic-chip>
										<input
											id="d_exludedSpeciality"
											#d_exludedSpeciality
											matInput
											[matChipInputFor]="c_childSpeciality"
											[formControl]="c_specialityControl"
											[matAutocomplete]="cSpecSpecialityAuto"
											floatPlaceholder="never"
											[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
										/>
									</mat-chip-list>
									<mat-autocomplete #cSpecSpecialityAuto="matAutocomplete" class="app-autocomplete">
										<mat-option
											*ngFor="let spec of c_specialityList"
											[value]="spec"
											(click)="selectDialogFilter('classificationSpeciality', spec, 'speciality')"
										>
											{{ spec.speciality }} ({{ spec.noOfPhysicians | numberWithCommas }})
										</mat-option>
									</mat-autocomplete>
								</mat-form-field>

								<span class="form-label">Sub Specialty</span>
								<mat-form-field>
									<mat-chip-list #c_subChildSpeciality>
										<mat-basic-chip
											*ngFor="let spec of tempDialogFilters.subSpeciality"
											[selectable]="selectable"
											[removable]="removable"
											(removed)="removeDialogFilter('classificationSpeciality', spec, 'subSpeciality')"
										>
											{{ spec }}
											<mat-icon matChipRemove>close</mat-icon>
										</mat-basic-chip>
										<input
											id="d_exludedSpeciality"
											#d_exludedSpeciality
											matInput
											[matChipInputFor]="c_subChildSpeciality"
											[formControl]="c_subSpecialityControl"
											[matAutocomplete]="cSpecSubSpecialityAuto"
											floatPlaceholder="never"
											[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
										/>
									</mat-chip-list>
									<mat-autocomplete #cSpecSubSpecialityAuto="matAutocomplete" class="app-autocomplete">
										<mat-option
											(click)="selectDialogFilter('classificationSpeciality', spec, 'subSpeciality')"
											*ngFor="let spec of c_subSpecialityList"
										>
											{{ spec.subspeciality }} ({{ spec.noOfPhysicians | numberWithCommas }})
										</mat-option>
									</mat-autocomplete>
								</mat-form-field>
							</div>
						</mat-expansion-panel>
						<div
							class="second-header d-flex"
							*ngIf="tempDialogFilters.specialityGroup != '' && !c_specPanel.expanded"
						>
							<mat-chip-list #includeSpecialityList>
								<ng-container>
									<mat-basic-chip
										[removable]="removable"
										(removed)="
											removeDialogFilter('classificationSpeciality', tempDialogFilters.specialityGroup, 'specGroup')
										"
									>
										{{ tempDialogFilters.specialityGroup }}
										<mat-icon matChipRemove>close</mat-icon>
									</mat-basic-chip>
								</ng-container>

								<ng-container *ngIf="tempDialogFilters.speciality.length > 0">
									<mat-basic-chip
										*ngFor="let spec of tempDialogFilters.speciality"
										[removable]="removable"
										(removed)="removeDialogFilter('classificationSpeciality', spec, 'speciality')"
									>
										{{ spec.speciality }}
										<mat-icon matChipRemove>close</mat-icon>
									</mat-basic-chip>
								</ng-container>

								<ng-container *ngIf="tempDialogFilters.subSpeciality.length > 0">
									<mat-basic-chip
										*ngFor="let spec of tempDialogFilters.subSpeciality"
										[removable]="removable"
										(removed)="removeDialogFilter('classificationSpeciality', spec, 'subSpeciality')"
									>
										{{ spec }}
										<mat-icon matChipRemove>close</mat-icon>
									</mat-basic-chip>
								</ng-container>
							</mat-chip-list>
						</div>
					</div>
				</div>
				<!-- Classification Speciality component end -->

				<!-- Experience panel start-->
				<div class="dialog-flex-item">
					<div class="expansion-body">
						<mat-expansion-panel
							#panelExperience
							hideToggle="true"
							[disabled]="isPaid"
							(opened)="triggerAutoFocus('#experienceInput')"
						>
							<mat-expansion-panel-header
								[ngClass]="panelExperience.expanded ? 'expanded' : ''"
								[collapsedHeight]="'70px'"
								[expandedHeight]="'70px'"
							>
								<mat-panel-title>
									<div class="d-flex align-items-center">
										<mat-icon class="filter-title" *ngIf="isPaid">lock</mat-icon>Ailment
										<div class="ml-2 clear-more-filter" *ngIf="tempDialogFilters.experience.length > 0">
											<button (click)="clearFilter('experience'); $event.stopPropagation()">
												<mat-icon class="clear-more-filter-icon">close</mat-icon>
												<span class="clear-more-filter-text"> {{ tempDialogFilters.experience.length }} </span>
											</button>
										</div>
									</div>
								</mat-panel-title>
								<mat-panel-description>
									<mat-icon class="matrl-plus">{{ panelExperience.expanded ? 'remove' : 'add' }} </mat-icon>
								</mat-panel-description>
							</mat-expansion-panel-header>

							<div class="dialog-expansion-content">
								<mat-form-field>
									<mat-chip-list #experienceList aria-label="Experience input">
										<mat-basic-chip
											*ngFor="let name of tempDialogFilters.experience"
											[selectable]="selectable"
											[removable]="removable"
											(removed)="removeDialogFilter('experience', name)"
										>
											{{ name }}
											<mat-icon matChipRemove>close</mat-icon>
										</mat-basic-chip>
										<input
											#d_experience
											id="experienceInput"
											placeholder=""
											aria-label="Experience Input"
											matInput
											[formControl]="experienceControl"
											[matAutocomplete]="ExperienceAuto"
											floatPlaceholder="never"
											[matChipInputFor]="experienceList"
											[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
										/>
									</mat-chip-list>

									<mat-autocomplete
										#ExperienceAuto="matAutocomplete"
										(optionSelected)="selectDialogFilter('experience', $event); d_experience.value = ''"
										class="app-autocomplete"
									>
										<mat-option *ngFor="let experience of getExperienceList" [value]="experience">
											{{ experience }}
										</mat-option>
									</mat-autocomplete>
								</mat-form-field>
							</div>
						</mat-expansion-panel>

						<div
							class="second-header d-flex"
							*ngIf="tempDialogFilters.experience.length > 0 && !panelExperience.expanded"
						>
							<mat-chip-list #includeSpecialityList>
								<mat-basic-chip
									[removable]="removable"
									*ngFor="let experience of tempDialogFilters.experience"
									(removed)="removeDialogFilter('experience', experience)"
								>
									{{ experience }}
									<mat-icon matChipRemove>close</mat-icon>
								</mat-basic-chip>
							</mat-chip-list>
						</div>
					</div>
				</div>
				<!-- Experience panel End-->

				<!-- Procedure Code starts -->
				<div class="dialog-flex-item">
					<div class="expansion-body">
						<mat-expansion-panel
							#panelCodes
							hideToggle="true"
							[disabled]="isPaid"
							(opened)="triggerAutoFocus('#experienceInput')"
						>
							<mat-expansion-panel-header
								[ngClass]="panelCodes.expanded ? 'expanded' : ''"
								[collapsedHeight]="'70px'"
								[expandedHeight]="'70px'"
							>
								<mat-panel-title>
									<div class="d-flex align-items-center">
										<mat-icon class="filter-title" *ngIf="isPaid">lock</mat-icon>Procedure Codes
										<div
											class="ml-2 clear-more-filter"
											*ngIf="tempDialogFilters.hcpcsCodes.length > 0 || tempDialogFilters.cptCodes.length > 0"
										>
											<button (click)="clearFilter('procedureCode'); $event.stopPropagation()">
												<mat-icon class="clear-more-filter-icon">close</mat-icon>
												<span class="clear-more-filter-text">
													{{ tempDialogFilters.cptCodes.length + tempDialogFilters.hcpcsCodes.length }}
												</span>
											</button>
										</div>
									</div>
								</mat-panel-title>
								<mat-panel-description>
									<mat-icon class="matrl-plus">{{ panelCodes.expanded ? 'remove' : 'add' }} </mat-icon>
								</mat-panel-description>
							</mat-expansion-panel-header>

							<div class="dialog-expansion-content">
								<span class="form-label">CPT</span>
								<mat-form-field>
									<mat-chip-list #codeClassificationValue aria-label="code classification input">
										<mat-basic-chip
											*ngFor="let name of tempDialogFilters.cptCodes"
											[selectable]="selectable"
											[removable]="removable"
											(removed)="removeDialogFilter('procedureCode', name, 'cpt')"
										>
											{{ name }}
											<mat-icon matChipRemove>close</mat-icon>
										</mat-basic-chip>
										<input
											#d_cptCode
											id="codeClassificationInput"
											placeholder=""
											aria-label="code classification input"
											matInput
											[formControl]="codeClassificationControl"
											[matAutocomplete]="sicCodeAuto"
											floatPlaceholder="never"
											[matChipInputFor]="codeClassificationValue"
											[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
											class="email-status-input"
										/>
									</mat-chip-list>
									<mat-autocomplete
										#sicCodeAuto="matAutocomplete"
										(optionSelected)="selectDialogFilter('procedureCode', $event, 'cpt'); d_cptCode.value = ''"
										class="app-autocomplete"
									>
										<mat-option *ngFor="let cptCode of cptCodeList" [value]="cptCode.cptCode">
											{{ cptCode.cptCode }}-{{ cptCode.description }}
										</mat-option>
									</mat-autocomplete>
								</mat-form-field>
								<span class="form-label">HCPCS</span>
								<mat-form-field>
									<mat-chip-list #hcpcsList aria-label="Experience input">
										<mat-basic-chip
											*ngFor="let name of tempDialogFilters.hcpcsCodes"
											[selectable]="selectable"
											[removable]="removable"
											(removed)="removeDialogFilter('procedureCode', name, 'hcpcs')"
										>
											{{ name }}
											<mat-icon matChipRemove>close</mat-icon>
										</mat-basic-chip>
										<input
											#d_hcpcsCode
											id="cptCodeInput"
											placeholder=""
											aria-label="Experience Input"
											matInput
											[formControl]="cptControl"
											[matAutocomplete]="cptCodeAuto"
											floatPlaceholder="never"
											[matChipInputFor]="hcpcsList"
											[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
										/>
									</mat-chip-list>

									<mat-autocomplete
										#cptCodeAuto="matAutocomplete"
										(optionSelected)="selectDialogFilter('procedureCode', $event, 'hcpcs'); d_hcpcsCode.value = ''"
										class="app-autocomplete"
									>
										<mat-option *ngFor="let cptCode of hscpcCodeList" [value]="cptCode.hcpcsCode">
											{{ cptCode.hcpcsCode }}-{{ cptCode.description }}
										</mat-option>
									</mat-autocomplete>
								</mat-form-field>
							</div>
						</mat-expansion-panel>

						<div
							class="second-header d-flex"
							*ngIf="
								(tempDialogFilters.cptCodes.length > 0 || tempDialogFilters.hcpcsCodes.length > 0) &&
								!panelCodes.expanded
							"
						>
							<mat-chip-list #includeSpecialityList>
								<ng-container *ngIf="tempDialogFilters.cptCodes.length > 0">
									<mat-basic-chip
										*ngFor="let code of tempDialogFilters.cptCodes"
										[removable]="removable"
										(removed)="removeDialogFilter('procedureCode', code, 'cpt')"
									>
										{{ code }}
										<mat-icon matChipRemove>close</mat-icon>
									</mat-basic-chip>
								</ng-container>

								<ng-container *ngIf="tempDialogFilters.hcpcsCodes.length > 0">
									<mat-basic-chip
										*ngFor="let code of tempDialogFilters.hcpcsCodes"
										[removable]="removable"
										(removed)="removeDialogFilter('procedureCode', code, 'hcpcs')"
									>
										{{ code }}
										<mat-icon matChipRemove>close</mat-icon>
									</mat-basic-chip>
								</ng-container>
							</mat-chip-list>
						</div>
					</div>
				</div>
				<!-- Procedure Code ends -->

				<!-- Email Status component starts -->
				<div class="dialog-flex-item">
					<div class="expansion-body">
						<mat-expansion-panel
							#panelEmailStatus
							hideToggle="true"
							[disabled]="isPaid"
							(opened)="triggerAutoFocus('#emailStatusInput')"
						>
							<mat-expansion-panel-header
								[ngClass]="panelEmailStatus.expanded ? 'expanded' : ''"
								[collapsedHeight]="'70px'"
								[expandedHeight]="'70px'"
							>
								<mat-panel-title>
									<div class="d-flex align-items-center">
										<mat-icon class="filter-title" *ngIf="isPaid">lock</mat-icon>
										Email Status
										<div class="ml-2 clear-more-filter" *ngIf="tempDialogFilters.email_Score !== 0">
											<button (click)="clearFilter('emailStatus'); $event.stopPropagation()">
												<mat-icon class="clear-more-filter-icon">close</mat-icon>
												<span class="clear-more-filter-text">1</span>
											</button>
										</div>
									</div>
								</mat-panel-title>

								<mat-panel-description>
									<mat-icon class="matrl-plus">{{ panelEmailStatus.expanded ? 'remove' : 'add' }}</mat-icon>
								</mat-panel-description>
							</mat-expansion-panel-header>

							<div class="dialog-expansion-content">
								<span class="form-label">Verified Days</span>
								<mat-form-field>
									<mat-chip-list #emailStatusDays aria-label="Email status input">
										<mat-basic-chip
											[selectable]="selectable"
											[removable]="removable"
											(removed)="removeDialogFilter('emailStatus', 0)"
											*ngIf="tempDialogFilters.email_Score !== 0"
										>
											{{ tempDialogFilters.email_Score }}
											<mat-icon matChipRemove>close</mat-icon>
										</mat-basic-chip>

										<input
											#emailStatusInput
											id="emailStatusInput"
											placeholder=" "
											aria-label="Email Status Input"
											matInput
											[formControl]="emailStatusControl"
											[matAutocomplete]="emailStatusAuto"
											floatPlaceholder="never"
											[matChipInputFor]="emailStatusDays"
											[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
											class="email-status-input"
										/>
									</mat-chip-list>
									<mat-autocomplete
										#emailStatusAuto="matAutocomplete"
										(optionSelected)="selectDialogFilter('emailStatus', $event)"
										class="app-autocomplete"
									>
										<mat-option [value]="30">30 Days</mat-option>
										<mat-option [value]="60">60 Days</mat-option>
										<mat-option [value]="120">120 Days</mat-option>
									</mat-autocomplete>
								</mat-form-field>
								<div class="confidence-level-container">
									<span class="form-label">Confidence Level(%)</span>
									<app-confidence-level
										[emailStatus]="tempDialogFilters.email_Score"
										(addRange)="selectDialogFilter('emailStatus', $event)"
										(removeRange)="removeEmailStatus()"
									></app-confidence-level>
								</div>
							</div>
						</mat-expansion-panel>
						<div
							class="second-header d-flex"
							*ngIf="tempDialogFilters.email_Score !== 0 && !panelEmailStatus.expanded"
						>
							<mat-chip-list #includeSpecialityList>
								<mat-basic-chip [removable]="removable" (removed)="removeDialogFilter('emailStatus', 0)">
									{{ tempDialogFilters.email_Score }}
									<mat-icon matChipRemove>close</mat-icon>
								</mat-basic-chip>
							</mat-chip-list>
						</div>
					</div>
				</div>
				<!-- Email Status component ends -->

				<!-- Hospital Name component starts-->
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Hospital"
					placeholder="Hospital name, Firm type, Hospital classification"
					[selectedData]="moreFilterHospitalData"
					(clear)="clearMoreFilterHospital()"
				>
					<app-auto-complete-drop-drow
						title="Hospital Name"
						[(list)]="filteredHospitals"
						(onChange)="getHospitalName($event)"
						(selectedDataChange)="selectDialogFilter('hospitalName', $event)"
						[itemName]="'hospitalName'"
						[itemValue]="'hospitalName'"
						[(selectedData)]="tempDialogFilters.hospitalNameList"
					></app-auto-complete-drop-drow>

					<app-signle-select-drop-down
						title="Select Firm Type"
						[(list)]="hospitalFirmType"
						[(selectedData)]="tempDialogFilters.firmtype"
						(onItemSelect)="getRecordCount()"
					></app-signle-select-drop-down>

					<app-select-drop-down
						title="Select Hospital Classification"
						[list]="hospitalClassification"
						(onItemSelect)="getRecordCount()"
						[(selectedData)]="tempDialogFilters.classification"
					></app-select-drop-down>

					<div class="chips-container">
						<app-chip-container
							[(data)]="tempDialogFilters.hospitalNameList"
							(onChange)="getRecordCount()"
							*ngIf="tempDialogFilters.hospitalNameList.length > 0"
						></app-chip-container>
						<app-chip-container
							[(data)]="tempDialogFilters.firmtype"
							(onChange)="getRecordCount()"
							[isSingle]="true"
							*ngIf="tempDialogFilters.firmtype !== ''"
						></app-chip-container>
						<app-chip-container
							[(data)]="tempDialogFilters.classification"
							(onChange)="getRecordCount()"
							*ngIf="tempDialogFilters.classification.length > 0"
						></app-chip-container>
					</div>
				</app-more-filter-expand-item>
				<!-- Hospital Name component end-->

				<!-- NPI number component starts-->
				<div class="dialog-flex-item">
					<div class="expansion-body">
						<mat-expansion-panel
							#panelNpi
							hideToggle="true"
							[disabled]="isPaid"
							(opened)="triggerAutoFocus('#npiNumberInput')"
						>
							<mat-expansion-panel-header
								[ngClass]="panelNpi.expanded ? 'expanded' : ''"
								[collapsedHeight]="'70px'"
								[expandedHeight]="'70px'"
							>
								<mat-panel-title>
									<div class="d-flex align-items-center">
										<mat-icon class="filter-title" *ngIf="isPaid">lock</mat-icon>NPI Number
										<div class="ml-2 clear-more-filter" *ngIf="tempDialogFilters.npiNumber.length > 0">
											<button (click)="clearFilter('npi'); $event.stopPropagation()">
												<mat-icon class="clear-more-filter-icon">close</mat-icon>
												<span class="clear-more-filter-text"> {{ tempDialogFilters.npiNumber.length }} </span>
											</button>
										</div>
									</div>
								</mat-panel-title>
								<mat-panel-description>
									<mat-icon class="matrl-plus">{{ panelNpi.expanded ? 'remove' : 'add' }}</mat-icon>
								</mat-panel-description>
							</mat-expansion-panel-header>

							<div class="dialog-expansion-content">
								<mat-form-field>
									<mat-chip-list #npiNumberChipList aria-label="NPI number input">
										<mat-basic-chip
											*ngFor="let npi of tempDialogFilters.npiNumber"
											[selectable]="selectable"
											[removable]="removable"
											(removed)="removeDialogFilter('npi', npi)"
										>
											{{ npi }}
											<mat-icon matChipRemove>close</mat-icon>
										</mat-basic-chip>
										<input
											#d_npiNumberInput
											id="npiNumberInput"
											aria-label="Number"
											matInput
											[formControl]="npiNumberControl"
											[matAutocomplete]="npiNumberAuto"
											floatPlaceholder="never"
											[matChipInputFor]="npiNumberChipList"
											[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
											numbersOnly
										/>
									</mat-chip-list>

									<mat-autocomplete #npiNumberAuto="matAutocomplete" class="app-autocomplete">
										<mat-option
											*ngFor="let npi of npiNumberList"
											[value]="npi"
											(click)="selectDialogFilter('npi', npi); d_npiNumberInput.value = ''"
										>
											<mat-checkbox [checked]="npiNumbers.includes(npi)"></mat-checkbox>
											{{ npi }}
										</mat-option>
									</mat-autocomplete>
								</mat-form-field>
							</div>
						</mat-expansion-panel>

						<div class="second-header d-flex" *ngIf="tempDialogFilters.npiNumber.length > 0 && !panelNpi.expanded">
							<mat-chip-list #includeSpecialityList>
								<mat-basic-chip
									[removable]="removable"
									*ngFor="let npi of tempDialogFilters.npiNumber"
									(removed)="removeDialogFilter('npi', npi)"
								>
									{{ npi }}
									<mat-icon matChipRemove>close</mat-icon>
								</mat-basic-chip>
							</mat-chip-list>
						</div>
					</div>
				</div>
				<!-- NPI number component End-->

				<!-- Location component starts-->
				<div class="dialog-flex-item">
					<div class="expansion-body">
						<mat-expansion-panel
							#panelLocation
							hideToggle="true"
							[disabled]="isPaid"
							(opened)="triggerAutoFocus('#stateInput')"
						>
							<mat-expansion-panel-header
								[ngClass]="panelLocation.expanded ? 'expanded' : ''"
								[collapsedHeight]="'70px'"
								[expandedHeight]="'70px'"
							>
								<mat-panel-title>
									<div class="d-flex align-items-center">
										<mat-icon class="filter-title" *ngIf="isPaid">lock</mat-icon>Location

										<div
											class="ml-2 clear-more-filter"
											*ngIf="
												tempDialogFilters.stateList.length > 0 ||
												tempDialogFilters.cityList.length > 0 ||
												tempDialogFilters.zipcode.length > 0
											"
										>
											<button (click)="clearFilter('location'); $event.stopPropagation()">
												<mat-icon class="clear-more-filter-icon">close</mat-icon>
												<span class="clear-more-filter-text">
													{{
														tempDialogFilters.stateList.length +
															tempDialogFilters.cityList.length +
															tempDialogFilters.zipcode.length
													}}
												</span>
											</button>
										</div>
									</div>
								</mat-panel-title>
								<mat-panel-description>
									<mat-icon class="matrl-plus">{{ panelLocation.expanded ? 'remove' : 'add' }}</mat-icon>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<div class="dialog-expansion-content">
								<span class="form-label">State</span>
								<mat-form-field>
									<mat-chip-list #statesChipList aria-label="State input">
										<mat-basic-chip
											*ngFor="let state of tempDialogFilters.stateList"
											[selectable]="selectable"
											[removable]="removable"
											(removed)="removeDialogFilter('location', state, 'state')"
										>
											{{ state.stateFullName }}
											<mat-icon matChipRemove>close</mat-icon>
										</mat-basic-chip>
										<input
											#d_stateInput
											id="stateInput"
											aria-label="stateList"
											matInput
											[formControl]="stateControl"
											[matAutocomplete]="stateAuto"
											floatPlaceholder="never"
											[matChipInputFor]="statesChipList"
											[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
										/>
									</mat-chip-list>

									<mat-autocomplete
										#stateAuto="matAutocomplete"
										(optionSelected)="selectDialogFilter('location', $event, 'state'); d_stateInput.value = ''"
										panelClass="app-autocomplete"
										class="app-autocomplete"
									>
										<mat-option *ngFor="let state of filteredStates" [value]="state">
											{{ state.stateFullName }}
										</mat-option>
									</mat-autocomplete>
								</mat-form-field>
								<span class="form-label">City</span>
								<mat-form-field>
									<mat-chip-list #cityList aria-label="city name input">
										<mat-basic-chip
											*ngFor="let city of tempDialogFilters.cityList"
											[selectable]="selectable"
											[removable]="removable"
											(removed)="removeDialogFilter('location', city, 'city')"
										>
											{{ city.city }}
											<mat-icon matChipRemove>close</mat-icon>
										</mat-basic-chip>
										<input
											#d_cityInput
											aria-label="Search city"
											matInput
											[formControl]="cityControl"
											[matAutocomplete]="cityAuto"
											floatPlaceholder="never"
											[matChipInputFor]="cityList"
											[matChipInputSeparatorKeyCodes]="[]"
										/>
									</mat-chip-list>

									<mat-autocomplete #cityAuto="matAutocomplete" class="app-autocomplete">
										<mat-option
											*ngFor="let city of searchCity"
											[value]="city"
											(click)="selectDialogFilter('location', city, 'city'); d_cityInput.value = ''"
										>
											{{ city.city }}
										</mat-option>
									</mat-autocomplete>
								</mat-form-field>

								<span class="form-label">Zip Code</span>
								<mat-form-field>
									<mat-chip-list #zipCodeChipList aria-label="Zip COde">
										<mat-basic-chip
											*ngFor="let zip of tempDialogFilters.zipcode"
											[selectable]="selectable"
											[removable]="true"
											(removed)="removeDialogFilter('zip', zip)"
										>
											{{ zip }}
											<mat-icon matChipRemove>close</mat-icon>
										</mat-basic-chip>
										<input
											#zipInput
											id="zipCodeInput"
											aria-label="Number"
											matInput
											[formControl]="zipControl"
											[matAutocomplete]="zipCodeAuto"
											floatPlaceholder="never"
											[matChipInputFor]="zipCodeChipList"
											[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
											numbersOnly
										/>
									</mat-chip-list>

									<mat-autocomplete #zipCodeAuto="matAutocomplete" class="app-autocomplete">
										<mat-option
											*ngFor="let zip of zipCodeList"
											[value]="zip"
											(click)="selectDialogFilter('zip', zip); zipInput.value = ''"
										>
											<mat-checkbox [checked]="tempDialogFilters.zipcode.includes(zip)"></mat-checkbox>
											{{ zip }}
										</mat-option>
									</mat-autocomplete>
								</mat-form-field>
							</div>
						</mat-expansion-panel>

						<div
							class="second-header d-flex"
							*ngIf="
								(tempDialogFilters.stateList.length > 0 || tempDialogFilters.cityList.length > 0) &&
								!panelLocation.expanded
							"
						>
							<mat-chip-list #includeSpecialityList>
								<ng-container *ngIf="tempDialogFilters.stateList.length > 0">
									<mat-basic-chip
										[removable]="removable"
										*ngFor="let state of tempDialogFilters.stateList"
										(removed)="removeDialogFilter('location', state, 'state')"
									>
										{{ state.stateFullName }}
										<mat-icon matChipRemove>close</mat-icon>
									</mat-basic-chip>
								</ng-container>

								<ng-container *ngIf="tempDialogFilters.cityList.length > 0">
									<mat-basic-chip
										[removable]="removable"
										*ngFor="let city of tempDialogFilters.cityList"
										(removed)="removeDialogFilter('location', city, 'city')"
									>
										{{ city.city }}
										<mat-icon matChipRemove>close</mat-icon>
									</mat-basic-chip>
								</ng-container>
							</mat-chip-list>
						</div>
					</div>
				</div>
				<!-- Location component ends-->

				<!-- Age Filter Starts -->
				<div class="dialog-flex-item">
					<div class="expansion-body">
						<mat-expansion-panel
							#panelAge
							hideToggle="true"
							[disabled]="isPaid"
							(opened)="triggerAutoFocus('#stateInput')"
						>
							<mat-expansion-panel-header
								[ngClass]="panelAge.expanded ? 'expanded' : ''"
								[collapsedHeight]="'70px'"
								[expandedHeight]="'70px'"
							>
								<mat-panel-title>
									<div class="d-flex align-items-center">
										<mat-icon class="filter-title" *ngIf="isPaid">lock</mat-icon>Age

										<div class="ml-2 clear-more-filter" *ngIf="tempDialogFilters.ageRangeList.length > 0">
											<button (click)="clearFilter('age'); $event.stopPropagation()">
												<mat-icon class="clear-more-filter-icon">close</mat-icon>
												<span class="clear-more-filter-text"> {{ tempDialogFilters.ageRangeList.length }} </span>
											</button>
										</div>
									</div>
								</mat-panel-title>
								<mat-panel-description>
									<mat-icon class="matrl-plus">{{ panelAge.expanded ? 'remove' : 'add' }}</mat-icon>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<div class="dialog-expansion-content">
								<span class="form-label">Age Group</span>
								<mat-form-field>
									<mat-select multiple [(value)]="tempDialogFilters.ageRangeList">
										<mat-select-trigger>
											<mat-chip-list>
												<mat-basic-chip
													[removable]="removable"
													*ngFor="let age of tempDialogFilters.ageRangeList"
													class="chip-list select-basic-chip"
													(removed)="removeDialogFilter('age', age)"
												>
													{{ age }} <mat-icon matChipRemove>close</mat-icon></mat-basic-chip
												>
											</mat-chip-list>
										</mat-select-trigger>

										<mat-option *ngFor="let age of ageList" [value]="age" (click)="selectDialogFilter('age', age)">
											{{ age }}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
						</mat-expansion-panel>

						<div
							class="second-header d-flex"
							*ngIf="tempDialogFilters.ageRangeList.length > 0 && !panelAge.expanded"
						>
							<mat-chip-list #includeSpecialityList>
								<ng-container>
									<mat-basic-chip
										[removable]="removable"
										*ngFor="let age of tempDialogFilters.ageRangeList"
										(removed)="removeDialogFilter('age', age)"
									>
										{{ age }}
										<mat-icon matChipRemove>close</mat-icon>
									</mat-basic-chip>
								</ng-container>
							</mat-chip-list>
						</div>
					</div>
				</div>
				<!-- Age Filter Ends -->

				<!-- Languages component starts -->
				<div class="dialog-flex-item">
					<div class="expansion-body">
						<mat-expansion-panel
							#panelLanguages
							hideToggle="true"
							[disabled]="isPaid"
							(opened)="triggerAutoFocus('#experienceInput'); action_language('api', '')"
							(closed)="d_languages.value = ''"
						>
							<mat-expansion-panel-header
								[ngClass]="panelLanguages.expanded ? 'expanded' : ''"
								[collapsedHeight]="'70px'"
								[expandedHeight]="'70px'"
							>
								<mat-panel-title>
									<div class="d-flex align-items-center">
										<mat-icon class="filter-title" *ngIf="isPaid">lock</mat-icon>Languages
										<div class="ml-2 clear-more-filter" *ngIf="tempDialogFilters.languages.length > 0">
											<button (click)="clearFilter('language'); $event.stopPropagation()">
												<mat-icon class="clear-more-filter-icon">close</mat-icon>
												<span class="clear-more-filter-text"> {{ tempDialogFilters.languages.length }} </span>
											</button>
										</div>
									</div>
								</mat-panel-title>
								<mat-panel-description>
									<mat-icon class="matrl-plus">{{ panelLanguages.expanded ? 'remove' : 'add' }} </mat-icon>
								</mat-panel-description>
							</mat-expansion-panel-header>

							<div class="dialog-expansion-content">
								<mat-form-field>
									<mat-chip-list #languagesList>
										<mat-basic-chip
											*ngFor="let language of tempDialogFilters.languages"
											[selectable]="selectable"
											[removable]="removable"
											(removed)="removeDialogFilter('language', language)"
										>
											{{ language }}
											<mat-icon matChipRemove>close</mat-icon>
										</mat-basic-chip>
										<input
											#d_languages
											id="languagesInput"
											placeholder=""
											matInput
											[formControl]="languagesControl"
											[matAutocomplete]="languagesAuto"
											floatPlaceholder="never"
											[matChipInputFor]="languagesList"
											[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
										/>
									</mat-chip-list>

									<mat-autocomplete
										#languagesAuto="matAutocomplete"
										(optionSelected)="selectDialogFilter('language', $event); d_languages.value = ''"
										class="app-autocomplete"
									>
										<mat-option *ngFor="let language of filteredLanguageList" [value]="language">
											{{ language }}
										</mat-option>
									</mat-autocomplete>
								</mat-form-field>
							</div>
						</mat-expansion-panel>

						<div
							class="second-header d-flex"
							*ngIf="tempDialogFilters.languages.length > 0 && !panelLanguages.expanded"
						>
							<mat-chip-list #includeSpecialityList>
								<mat-basic-chip
									[removable]="removable"
									*ngFor="let language of tempDialogFilters.languages"
									(removed)="removeDialogFilter('language', language)"
								>
									{{ language }}
									<mat-icon matChipRemove>close</mat-icon>
								</mat-basic-chip>
							</mat-chip-list>
						</div>
					</div>
				</div>
				<!-- Languages component ends -->

				<!-- Registered Nurse Starts -->
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Registered Nurse with BSN"
					[(selectedData)]="tempDialogFilters.bsn"
					[single]="true"
					(omitChange)="getRecordCount()"
				>
					<app-signle-select-drop-down
						[list]="bsnList"
						[(selectedData)]="tempDialogFilters.bsn"
						(onItemSelect)="getRecordCount()"
					></app-signle-select-drop-down>
				</app-more-filter-expand-item>
				<!-- Registered Nurse Ends -->

				<!-- Tele Health Starts -->
				<div class="dialog-flex-item">
					<div class="expansion-body">
						<mat-expansion-panel #panelTeleHealth hideToggle="true" [disabled]="isPaid">
							<mat-expansion-panel-header
								[ngClass]="panelTeleHealth.expanded ? 'expanded' : ''"
								[collapsedHeight]="'70px'"
								[expandedHeight]="'70px'"
							>
								<mat-panel-title>
									<div class="d-flex align-items-center">
										<mat-icon class="filter-title" *ngIf="isPaid">lock</mat-icon>Telehealth
										<div class="ml-2 clear-more-filter" *ngIf="tempDialogFilters.istelehealth == 'Y'">
											<button (click)="action_teleHealth('clear'); $event.stopPropagation()">
												<mat-icon class="clear-more-filter-icon">close</mat-icon>
												<span class="clear-more-filter-text"> 1 </span>
											</button>
										</div>
									</div>
								</mat-panel-title>
								<mat-panel-description>
									<mat-icon class="matrl-plus">{{ panelTeleHealth.expanded ? 'remove' : 'add' }}</mat-icon>
								</mat-panel-description>
							</mat-expansion-panel-header>

							<div class="toggle-container d-flex align-items-center justify-content-between mt-3">
								<div class="text-grey font-13">Telehealth Services</div>
								<div class="toggle-switch">
									<input
										class="toggle-input"
										id="d_TeleHealth"
										type="checkbox"
										[(ngModel)]="d_teleHealth"
										(ngModelChange)="action_teleHealth('alterValue')"
									/>
									<label class="toggle-label" for="d_TeleHealth"></label>
								</div>
							</div>
						</mat-expansion-panel>
					</div>
				</div>
				<!-- Tele Health Ends -->

				<!-- EHR Devices Starts -->
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="EHR/EMR Devices"
					(clear)="
						getRecordCount(); tempDialogFilters.ehrproductname = []; tempDialogFilters.productclassification = []
					"
					[selectedData]="ehrSelectedData"
				>
					<app-auto-complete-drop-drow
						title="EHR/EMR Product Name"
						[(selectedData)]="tempDialogFilters.ehrproductname"
						(onItemSelect)="getRecordCount()"
						[(list)]="ehrProductNameList"
						(onChange)="getEHRProductName($event)"
					></app-auto-complete-drop-drow>

					<app-select-drop-down
						title="EHR/EMR Product Classification"
						[(list)]="ehrProductClassification"
						[(selectedData)]="tempDialogFilters.productclassification"
						(onItemSelect)="getRecordCount()"
					></app-select-drop-down>
					<div class="chips-container">
						<app-chip-container
							[(data)]="tempDialogFilters.ehrproductname"
							(onChange)="getRecordCount()"
						></app-chip-container>
						<app-chip-container
							[(data)]="tempDialogFilters.productclassification"
							(onChange)="getRecordCount()"
						></app-chip-container>
					</div>
				</app-more-filter-expand-item>
				<!-- EHR Devices Ends -->

				<!-- Total claims starts -->
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Claims"
					[(selectedData)]="tempDialogFilters.totalclaimsrange"
					(omitChange)="getRecordCount()"
					(clear)="tempDialogFilters.totalclaimsrange = []; getRecordCount()"
				>
					<app-select-drop-down
						title="Number of Claims"
						[(list)]="totalClaimsRange"
						[(selectedData)]="tempDialogFilters.totalclaimsrange"
						(onItemSelect)="getRecordCount()"
					></app-select-drop-down>
					<div class="chips-container">
						<app-chip-container
							[(data)]="tempDialogFilters.totalclaimsrange"
							(onChange)="getRecordCount()"
						></app-chip-container>
					</div>
				</app-more-filter-expand-item>
				<!-- total claims ends -->

				<!-- Zip Code By Radius starts -->
				<ng-container *ngIf="isZipCodeRadius">
					<app-more-filter-expand-item
						class="dialog-flex-container"
						title="Zip Code by Radius"
						[(selectedData)]="selectedZipCodeForRadius"
						(omitChange)="getRecordCount()"
						(clear)="action_zipCodeByRadius('clear')"
						[single]="true"
					>
						<app-keyword-input
							title="Zip Code"
							[(selectedSingleData)]="selectedZipCodeForRadius"
							(selectedSingleDataChange)="getZipCodeForRadius()"
							[numberOnly]="true"
							[isSingle]="true"
						></app-keyword-input>

						<mat-form-field>
							<mat-label>Radius</mat-label>
							<mat-select [(value)]="selectedRadius" (selectionChange)="getZipCodeForRadius()">
								<mat-option value="25">Within 25 miles</mat-option>
								<mat-option value="50">Within 50 miles</mat-option>
								<mat-option value="100">Within 100 miles</mat-option>
								<mat-option value="200">Within 200 miles</mat-option>
							</mat-select>
						</mat-form-field>
					</app-more-filter-expand-item>
				</ng-container>
				<!-- Zip Code By Radius ends -->

				<!-- Certificate Starts -->
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Certificates"
					[selectedData]="selectedCertificatesData"
					(clear)="tempDialogFilters.abom = ''; tempDialogFilters.abmsabps = ''; getRecordCount()"
					(omitChange)="getRecordCount()"
				>
					<app-signle-select-drop-down
						title="ABOM"
						[list]="certificatesOptions"
						[(selectedData)]="tempDialogFilters.abom"
						(onItemSelect)="getRecordCount()"
					></app-signle-select-drop-down>
					<app-signle-select-drop-down
						title="ABMS/ABPS"
						[list]="certificatesOptions"
						[(selectedData)]="tempDialogFilters.abmsabps"
						(onItemSelect)="getRecordCount()"
					></app-signle-select-drop-down>

					<div class="chips-container">
						<app-chip-container
							[(data)]="tempDialogFilters.abmsabps"
							(onChange)="getRecordCount()"
							isSingle="true"
						></app-chip-container>
						<app-chip-container
							[(data)]="tempDialogFilters.abom"
							(onChange)="getRecordCount()"
							isSingle="true"
						></app-chip-container>
					</div>
				</app-more-filter-expand-item>
				<!-- Certificate Ends -->

				<!-- Education Filter Starts -->
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Education"
					[selectedData]="educationFilter"
					(clear)="action_education('clear')"
					(omitChange)="getRecordCount()"
					[itemName]="'name'"
					[single]="false"
				>
					<span class="form-label">Residency Hospital</span>
					<mat-form-field>
						<mat-chip-list #residencyChipList aria-label="residency">
							<mat-basic-chip
								*ngFor="let spec of tempDialogFilters.residencyhospitals"
								[selectable]="selectable"
								[removable]="removable"
								(removed)="removeEducationFilter('residencyhospitals', spec.name)"
							>
								{{ spec.name }}
								<mat-icon matChipRemove>close</mat-icon>
							</mat-basic-chip>
							<input
								#d_residencyHospital
								aria-label="Residency Hospital"
								matInput
								placeholder=""
								(keyup)="getEducationFilterList('residencyhospitals', $event.target.value)"
								[matAutocomplete]="residencyHospitalAuto"
								floatPlaceholder="never"
								[matChipInputFor]="residencyChipList"
								[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
							/>
						</mat-chip-list>
						<mat-autocomplete
							#residencyHospitalAuto="matAutocomplete"
							(optionSelected)="
								selectedEducationFilter('residencyhospitals', $event.option.value); d_residencyHospital.value = ''
							"
							class="app-autocomplete"
						>
							<mat-option *ngFor="let spec of filteredResidencyHospitalList" [value]="spec"> {{ spec }} </mat-option>
						</mat-autocomplete>
					</mat-form-field>

					<span class="form-label">Fellowship</span>
					<mat-form-field>
						<mat-chip-list #fellowShipChipList aria-label="fellowship">
							<mat-basic-chip
								*ngFor="let spec of tempDialogFilters.fellowships"
								[selectable]="selectable"
								[removable]="removable"
								(removed)="removeEducationFilter('fellowships', spec.name)"
							>
								{{ spec.name }}
								<mat-icon matChipRemove>close</mat-icon>
							</mat-basic-chip>
							<input
								#d_fellowshipsInput
								aria-label="Fellowships"
								matInput
								placeholder=""
								(keyup)="getEducationFilterList('fellowships', $event.target.value)"
								[matAutocomplete]="fellowShipAuto"
								floatPlaceholder="never"
								[matChipInputFor]="fellowShipChipList"
								[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
							/>
						</mat-chip-list>
						<mat-autocomplete
							#fellowShipAuto="matAutocomplete"
							(optionSelected)="
								selectedEducationFilter('fellowships', $event.option.value); d_fellowshipsInput.value = ''
							"
							class="app-autocomplete"
						>
							<mat-option *ngFor="let spec of filteredFellowshipList" [value]="spec"> {{ spec }} </mat-option>
						</mat-autocomplete>
					</mat-form-field>

					<span class="form-label">Medical School</span>
					<mat-form-field>
						<mat-chip-list #medicalSchoolChipList aria-label="fellowship">
							<mat-basic-chip
								*ngFor="let spec of tempDialogFilters.medicalschools"
								[selectable]="selectable"
								[removable]="removable"
								(removed)="removeEducationFilter('medicalschools', spec.name)"
							>
								{{ spec.name }}
								<mat-icon matChipRemove>close</mat-icon>
							</mat-basic-chip>
							<input
								#d_medicalschoolsInput
								aria-label="medicalschools"
								matInput
								placeholder=""
								(keyup)="getEducationFilterList('medicalschools', $event.target.value)"
								[matAutocomplete]="medicalSchoolAuto"
								floatPlaceholder="never"
								[matChipInputFor]="medicalSchoolChipList"
								[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
							/>
						</mat-chip-list>
						<mat-autocomplete
							#medicalSchoolAuto="matAutocomplete"
							(optionSelected)="
								selectedEducationFilter('medicalschools', $event.option.value); d_medicalschoolsInput.value = ''
							"
							class="app-autocomplete"
						>
							<mat-option *ngFor="let spec of filteredMedicalSchoolsList" [value]="spec"> {{ spec }} </mat-option>
						</mat-autocomplete>
					</mat-form-field>

					<!-- <app-keyword-input
						title="Residency Hospital"
						[storageKey]="'physician_education_residency'"
						[(selectedData)]="tempDialogFilters.residencyhospitals"
						(selectedDataChange)="getRecordCount()"
						[itemName]="'name'"
					></app-keyword-input> -->
					<!-- <app-keyword-input
						title="Fellowship"
						[storageKey]="'physician_education_fellowship'"
						[(selectedData)]="tempDialogFilters.fellowships"
						(selectedDataChange)="getRecordCount()"
						[itemName]="'name'"
					></app-keyword-input>
					<app-keyword-input
						title="Medical School"
						[storageKey]="'physician_education_medicalschool'"
						[(selectedData)]="tempDialogFilters.medicalschools"
						(selectedDataChange)="getRecordCount()"
						[itemName]="'name'"
					></app-keyword-input> -->

					<div class="chips-container">
						<app-chip-container
							*ngIf="tempDialogFilters.residencyhospitals.length > 0"
							[(data)]="tempDialogFilters.residencyhospitals"
							(onChange)="getRecordCount()"
							[itemName]="'name'"
						></app-chip-container>
						<app-chip-container
							*ngIf="tempDialogFilters.fellowships.length > 0"
							[(data)]="tempDialogFilters.fellowships"
							(onChange)="getRecordCount()"
							[itemName]="'name'"
						></app-chip-container>
						<app-chip-container
							*ngIf="tempDialogFilters.medicalschools.length > 0"
							[(data)]="tempDialogFilters.medicalschools"
							(onChange)="getRecordCount()"
							[itemName]="'name'"
						></app-chip-container>
					</div>
				</app-more-filter-expand-item>
				<!-- Education Filter Ends-->

				<!-- Educational Location Starts -->
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="Educational Location"
					[selectedData]="educationStateFilter"
					(clear)="actionEducationLocation('clear')"
					(omitChange)="getRecordCount()"
					[single]="false"
				>
					<app-auto-complete-drop-drow
						title="Under Graduate State"
						[(list)]="educationStateList"
						(onChange)="getStateList($event)"
						(selectedDataChange)="selectEducationState('undergraduate_state', $event)"
						[itemName]="'stateFullName'"
						[returnObject]="true"
						[(selectedData)]="tempDialogFilters.undergraduate_state"
					></app-auto-complete-drop-drow>
					<app-auto-complete-drop-drow
						title="Medical School State"
						[(list)]="educationStateList"
						(onChange)="getStateList($event)"
						(selectedDataChange)="selectEducationState('medicalschool_state', $event)"
						[itemName]="'stateFullName'"
						[returnObject]="true"
						[(selectedData)]="tempDialogFilters.medicalschool_state"
					></app-auto-complete-drop-drow>
					<app-auto-complete-drop-drow
						title="Residency State"
						[(list)]="educationStateList"
						(onChange)="getStateList($event)"
						(selectedDataChange)="selectEducationState('residency_state', $event)"
						[itemName]="'stateFullName'"
						[returnObject]="true"
						[(selectedData)]="tempDialogFilters.residency_state"
					></app-auto-complete-drop-drow>
					<app-auto-complete-drop-drow
						title="Fellowship State"
						[(list)]="educationStateList"
						(onChange)="getStateList($event)"
						(selectedDataChange)="selectEducationState('fellowship_state', $event)"
						[itemName]="'stateFullName'"
						[returnObject]="true"
						[(selectedData)]="tempDialogFilters.fellowship_state"
					></app-auto-complete-drop-drow>

					<div class="chips-container">
						<app-chip-container
							*ngIf="tempDialogFilters.undergraduate_state.length > 0"
							[(data)]="tempDialogFilters.undergraduate_state"
							[itemName]="'stateFullName'"
							(onChange)="getRecordCount()"
						></app-chip-container>
						<app-chip-container
							*ngIf="tempDialogFilters.medicalschool_state.length > 0"
							[(data)]="tempDialogFilters.medicalschool_state"
							[itemName]="'stateFullName'"
							(onChange)="getRecordCount()"
						></app-chip-container>
						<app-chip-container
							*ngIf="tempDialogFilters.residency_state.length > 0"
							[(data)]="tempDialogFilters.residency_state"
							[itemName]="'stateFullName'"
							(onChange)="getRecordCount()"
						></app-chip-container>
						<app-chip-container
							*ngIf="tempDialogFilters.fellowship_state.length > 0"
							[(data)]="tempDialogFilters.fellowship_state"
							[itemName]="'stateFullName'"
							(onChange)="getRecordCount()"
						></app-chip-container>
					</div>
				</app-more-filter-expand-item>
				<!-- Educational Location Ends -->

				<!-- State Licences Starts -->
				<app-more-filter-expand-item
					class="dialog-flex-container"
					title="State Licences"
					[selectedData]="tempDialogFilters.licenses_state"
					(clear)="tempDialogFilters.licenses_state = []; getRecordCount()"
					(omitChange)="getRecordCount()"
					[single]="false"
				>
					<app-auto-complete-drop-drow
						title=""
						[(list)]="educationStateList"
						(onChange)="getStateList($event)"
						(selectedDataChange)="tempDialogFilters.licenses_state = $event; getRecordCount()"
						[itemName]="'stateFullName'"
						[returnObject]="true"
						[(selectedData)]="tempDialogFilters.licenses_state"
					></app-auto-complete-drop-drow>

					<div class="chips-container">
						<app-chip-container
							*ngIf="tempDialogFilters.licenses_state.length > 0"
							[(data)]="tempDialogFilters.licenses_state"
							(onChange)="getRecordCount()"
							[itemName]="'stateFullName'"
						></app-chip-container>
					</div>
				</app-more-filter-expand-item>
				<!-- State Licences Ends -->

				<!-- Advance component starts -->
				<div class="dialog-flex-item">
					<div class="expansion-body">
						<mat-expansion-panel #panelAdvance hideToggle="true" [disabled]="isPaid">
							<mat-expansion-panel-header
								[ngClass]="panelLocation.expanded ? 'expanded' : ''"
								[collapsedHeight]="'70px'"
								[expandedHeight]="'70px'"
							>
								<mat-panel-title>
									<div class="d-flex align-items-center">
										<mat-icon class="filter-title" *ngIf="isPaid">lock</mat-icon>Advance
										<div
											class="ml-2 clear-more-filter"
											*ngIf="
												tempDialogFilters.provider_Type === 'Independent' || tempDialogFilters.emailTypeIsp === 'Yes'
											"
										>
											<button
												(click)="clearFilter('advance'); $event.stopPropagation()"
												*ngIf="d_advanceFilterLength > 0"
											>
												<mat-icon class="clear-more-filter-icon">close</mat-icon>
												<span class="clear-more-filter-text"> {{ d_advanceFilterLength }} </span>
											</button>
										</div>
									</div>
								</mat-panel-title>
								<mat-panel-description>
									<mat-icon class="matrl-plus">{{ panelAdvance.expanded ? 'remove' : 'add' }}</mat-icon>
								</mat-panel-description>
							</mat-expansion-panel-header>

							<div class="toggle-container d-flex align-items-center justify-content-between">
								<div class="text-grey font-13">Private Practitioners</div>
								<div class="toggle-switch">
									<input
										class="toggle-input"
										id="d_toggle"
										type="checkbox"
										[(ngModel)]="d_privatePractioner"
										(ngModelChange)="selectDialogFilter('advance', d_privatePractioner, 'privatePractioner')"
									/>
									<label class="toggle-label" for="d_toggle"></label>
								</div>
							</div>
							<div class="toggle-container d-flex align-items-center justify-content-between mt-3">
								<div class="text-grey font-13">Show Only Personal Email</div>
								<div class="toggle-switch">
									<input
										class="toggle-input"
										id="d_toggle_1"
										type="checkbox"
										[(ngModel)]="d_personalEmail"
										(ngModelChange)="selectDialogFilter('advance', d_personalEmail, 'personalEmail')"
									/>
									<label class="toggle-label" for="d_toggle_1"></label>
								</div>
							</div>
						</mat-expansion-panel>
					</div>
				</div>
				<!-- Advance Filter ends -->
			</div>
		</mat-accordion>
	</mat-dialog-content>

	<div class="confirm-dialog">
		<div class="confirm-dialog-overlay" [class.show-confirm-dialog]="showConfirmDialog">
			<div class="confirm-dialog-content">
				<div class="header-content">
					<span>Caution</span>
					<mat-icon class="close-icon" (click)="closeDialogBox('confirmDialog')">close</mat-icon>
				</div>

				<div class="main-content">
					<span
						>Modifications made to your filters are not saved. Leaving now will discard these changes.<br />Do you
						wish to continue?</span
					>
				</div>

				<div class="action-content">
					<button class="cancel-btn" (click)="closeDialogBox('confirmDialog')">Cancel</button>
					<button class="save-btn ml-2" (click)="closeDialogBox('mainDialog', true)">Yes</button>
				</div>
			</div>
		</div>
	</div>
</ng-template>
<!-- More filter ends -->
