<mat-button-toggle-group
  name="fontStyle"
  aria-label="Font Style"
  multiple
  class="confidence-level-container"
>
  <mat-button-toggle
    class="confidence-level-btn1"
    value="120"
    [checked]="emailStatus == 120"
    (change)="emitBtnValue($event)"
    [ngClass]="{ 'button-group-border': emailStatus == 120 }"
  >
   <70%
  </mat-button-toggle>
  <mat-button-toggle
    class="confidence-level-btn2"
    value="60"
    [checked]="emailStatus == 60"
    (change)="emitBtnValue($event)"
    [ngClass]="{ 'button-group-border': emailStatus == 60 }"
  >
    >70%
  </mat-button-toggle>
  <mat-button-toggle
    class="confidence-level-btn3"
    value="30"
    [checked]="emailStatus == 30"
    (change)="emitBtnValue($event)"
    [ngClass]="{ 'button-group-border': emailStatus == 30 }"
  >
    >85%
  </mat-button-toggle>
</mat-button-toggle-group>
