<div class="physican_container card-animate">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-8">
        <div class="physician_card">
          <div class="form-check">
            <input
              *ngIf="!checkboxDisabled && isSubscribed && !showRequestContactBtn && !isSaved"
              [(ngModel)]="checkboxSelected"
              (change)="handleCheckboxChange($event)"
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              [disabled]="!isSubscribed"
            />
            <div
              *ngIf="checkboxDisabled || showRequestContactBtn || isSaved"
              class="form-check-input partiallySelected"
            >
              <div class="insideContent"></div>
            </div>
            <!-- <i-feather *ngIf="!isSubscribed" name="Lock" class=" lockIcon"></i-feather> -->
          </div>
          <!-- <div
            class="physician_imgContainer"
            [ngClass]="{
              'with-border': contactInfo?.imageUrl === '' || contactInfo?.imageUrl == null
            }"
          >
            <img
              [src]="contactInfo.imageUrl"
              class="physician_img"
              [ngClass]="{
                'img-default-avatar': contactInfo?.imageUrl == 'https://www.ampliz.com/assets/img/user.png'
              }"
              *ngIf="contactInfo.imageUrl && contactInfo.imageUrl !== '' && contactInfo.imageUrl !== null"
            />
            <i-feather
              name="User"
              class="feather"
              *ngIf="contactInfo.imageUrl === '' || contactInfo.imageUrl == null"
            ></i-feather>
          </div> -->
          <div class="physician_content">
            <div class="physician-name-container row align-items-center">
              <h5 class="physician_name">{{ contactInfo?.fullName | titlecase }}</h5>
              <!-- <i
                *ngIf="contactInfo['linkedinURL']"
                class="fa fa-brands fa-linkedin"
                (click)="openUrl('linkedinURL')"
              ></i> -->
              <img
                class="ml-2"
                src="../../../../../../assets/images/linkedin.png"
                width="12px"
                height="12px"
                style="cursor: pointer"
                *ngIf="contactInfo['linkedinURL']"
                (click)="openUrl('linkedinURL')"
              />
            </div>
            <div class="physician_info">
              <span *ngIf="contactInfo?.title" style="margin-right: 15px">{{
                contactInfo?.title.toString() | titlecase
              }}</span>
              <span class="physican_location"
                ><i class="fa fa-map-marker pr-1" aria-hidden="true"></i>{{ contactInfo?.location | titlecase }}</span
              >
            </div>
            <div class="company_cont" *ngIf="contactInfo.company !== null">
              <div class="company-container" (click)="doSearchContact('companyList', contactInfo.company)">
                <i class="fa fa-building-o pr-1" aria-hidden="true"></i>
                <span>{{ contactInfo.company | trimUnwantedString | titlecase }}</span>
              </div>

              <!-- Company Info Panel -->
              <div
                class="company-info-container"
                (click)="getCompanyDetails(companyTrigger)"
                [matMenuTriggerFor]="companyPanel"
                #companyTrigger="matMenuTrigger"
                *ngIf="contactInfo.domain! !== null"
              >
                <!-- (mouseenter)="showCompanyPanel(companyTrigger)" -->
                <svg
                  viewBox="0 0 24 24"
                  width="12"
                  height="12"
                  stroke="currentColor"
                  stroke-width="2"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="css-i6dzq1 company-info-icon"
                  [style.color]="companyTrigger.menuOpen ? '#6d08be' : ''"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="12" y1="16" x2="12" y2="12"></line>
                  <line x1="12" y1="8" x2="12.01" y2="8"></line>
                </svg>
              </div>
            </div>
            <!-- Company Info panel end -->

            <!------------>
            <mat-chip-list>
              <mat-chip
                disableRipple="true"
                selectable="false"
                class="bottomChip"
                selected="false"
                disabled="true"
                (click)="doSearchContact('skillInclude', skill)"
                *ngFor="let skill of skillSet | slice : 0 : sliceLength"
              >
                {{ skill }}
              </mat-chip>
              <span *ngIf="skillSet.length > sliceLength" class="moreButton" (click)="handleShowMore()">
                {{ showMoreSkill ? 'More +' : 'Less -' }}
              </span>
            </mat-chip-list>
            <!------------>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-4">
        <div class="physican_additionInfo">
          <!-- <ng-container
            *ngIf="contactInfo.email != null && contactInfo.email != ''"
          >
            <span>
              <i class="fa fa-envelope-o" aria-hidden="true"></i>
              <a
                [href]="'mailto:' + contactInfo.email"
                class="physican_email"
                [attr.href]="!isEmailMasked ? 'mailto:' + contactInfo.email : null"
                [ngStyle]="isEmailMasked?{'cursor': 'default'} : {'cursor': 'pointer'}"
                >{{ contactInfo.email }}</a
              >
            </span>
          </ng-container> -->

          <!-- Email -->
          <ng-container *ngIf="showEmail">
            <span class="email-container" *ngFor="let item of emailToShow">
              <i class="fa fa-envelope-o" aria-hidden="true"></i>
              <a
                [href]="'mailto:' + contactInfo.email"
                class="physican_email"
                [attr.href]="!isEmailMasked ? 'mailto:' + contactInfo.email : null"
                [ngStyle]="isEmailMasked ? { cursor: 'default' } : { cursor: 'pointer' }"
                >{{ item }}</a
              >
            </span>
          </ng-container>
          <!-- If Email is not present -->
          <ng-container *ngIf="showRequestEmail">
            <span class="email-container">
              <i class="fa fa-envelope-o" aria-hidden="true"></i>
              <span class="physican_email" (click)="request('Request Email Access', contactInfo.contactId)"
                >Request Email</span
              >
            </span>
          </ng-container>
          <!-- ./Email -->

          <!-- Phone -->
          <ng-container *ngIf="showPhone">
            <div *ngFor="let item of phoneToShow">
              <span class="physican_phone" [class.email-text]="!isPhoneMasked">
                <i class="fa fa-phone" aria-hidden="true"></i>
                {{ item }}</span
              >
            </div>
          </ng-container>
          <!-- If Phone does't exist -->
          <!-- && showRequestPhone -->
          <ng-container *ngIf="showRequestPhone">
            <span class="physican_email" (click)="request('Request Phone Access', contactInfo.contactId)">
              <i class="fa fa-phone" aria-hidden="true"></i>
              Request Phone</span
            >
          </ng-container>
          <!-- ./Phone -->

          <ng-container *ngIf="showAllContacts">
            <span class="show-more-button" [matMenuTriggerFor]="aboveMenu" #t="matMenuTrigger">
              Show all
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="11"
                fill="currentColor"
                class="bi bi-chevron-down"
                viewBox="0 0 16 16"
                class="show-more-icon"
                [class.rotateIcon]="t.menuOpen"
              >
                <path
                  fill-rule="evenodd"
                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </span>

            <mat-menu class="show-more-menu" #aboveMenu="matMenu" xPosition="before" menuOpen="menuOpen">
              <div class="contact-container">
                <!-- email container starts -->
                <div class="email-wrapper" *ngIf="allEmail.length > 0">
                  <span class="email-container-heading">Email</span>
                  <div class="email-list-container">
                    <div class="email-list row" *ngFor="let contact of allEmail" (click)="$event.stopPropagation()">
                      <div class="email-list-left row col-4">
                        <i class="fa fa-envelope-o" aria-hidden="true"></i>
                        <div class="email-type-container">
                          <span class="email-type-text">{{ contact.type }}</span>
                        </div>
                      </div>
                      <div class="email-list-right col-8">
                        <div class="email-text-container">
                          <a
                            [href]="'mailto:' + contactInfo.email"
                            id="email-hover"
                            class="physican_email email-text"
                            [attr.href]="!isEmailMasked ? 'mailto:' + contactInfo.email : null"
                            >{{ contact.email }}</a
                          >
                          <div class="copy-clipboard-container">
                            <button class="copy-clipboard-icon-button" (click)="copyToClipboard(contact.email)">
                              <svg
                                viewBox="0 0 24 24"
                                width="16"
                                height="16"
                                stroke="currentColor"
                                stroke-width="1.5"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="css-i6dzq copy-clipboard-icon"
                              >
                                <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- email container ends -->

                <!-- Phone Container starts -->
                <div class="phone-wrapper" *ngIf="allPhone.length > 0">
                  <span class="phone-container-heading">Phone</span>
                  <div class="phone-list-container row" *ngFor="let phone of allPhone">
                    <div class="col-1">
                      <i class="fa fa-phone phone-list-icon" aria-hidden="true"></i>
                    </div>
                    <div class="phone-list-right col-7 row">
                      <div>
                        <span class="phone-list-text">{{ phone }}</span>
                      </div>
                      <div class="copy-clipboard-container">
                        <button class="copy-clipboard-icon-button" (click)="copyToClipboard(phone)">
                          <svg
                            viewBox="0 0 24 24"
                            width="16"
                            height="16"
                            stroke="currentColor"
                            stroke-width="1.5"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="css-i6dzq copy-clipboard-icon"
                          >
                            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Phone Container ends -->
              </div>
            </mat-menu>
          </ng-container>
          <div *ngIf="contactInfo['facebook'] || contactInfo['twitterURL']" class="saveBtn_wrp">
            <i *ngIf="contactInfo['facebook']" class="fa fa-brands fa-facebook" (click)="openUrl('facebook')"></i>
            <i *ngIf="contactInfo['twitterURL']" class="fa fa-brands fa-twitter" (click)="openUrl('twitterURL')"></i>
          </div>
          <div class="saveBtn_wrp row" [class.add-space]="showRequestContactBtn">
            <app-save-contact-modal
              *ngIf="showSaveContactModal"
              [selectedItems]="[contactInfo.contactId]"
              [isSaveButton]="showSaveButton"
              [fromCard]="true"
              (successfullyAdded)="contactReceived($event)"
              (contactViewed)="refreshValues($event)"
              class="col-4"
            ></app-save-contact-modal>
            <app-request-contact
              *ngIf="showRequestContactBtn"
              class="col-4"
              [platform]="'b2b'"
              [id]="contactInfo.contactId"
            ></app-request-contact>
            <app-not-correct class="col-6 not-correct-wrap" [contactId]="contactInfo.contactId"></app-not-correct>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-b2b-savelist
  [b2bSaveDrawer]="b2bSaveDrawer"
  (cancelBtnClick)="cancelBtnClick($event)"
  *ngIf="b2bSaveDrawer"
></app-b2b-savelist>

<!-- company panel menu -->

<mat-menu
  class="company-panel-menu"
  #companyPanel="matMenu"
  xPosition="before"
  menuOpen="menuOpen"
  yPosition="above"
  xPosition="after"
>
  <!-- (mouseleave)="hideCompanyPanel(companyTrigger)" -->
  <div class="company-panel-container" (click)="$event.stopPropagation()">
    <app-company-panel-loader *ngIf="companyPanelLoader" [loopCount]="6"></app-company-panel-loader>

    <div class="company-panel-container" *ngIf="!companyPanelLoader">
      <div class="company-panel-info col">

        <!-- companyName -->
        <div
          class="company-panel-detail row justify-space-around align-items-center"
          *ngIf="companyPanelList.companyName !== null"
        >
          <div class="company-logo-container col-2">
            <img
              [src]="companyPanelList.companyLogo"
              *ngIf="companyPanelList.companyLogo !== null"
            />
          </div>
          <img
            class="col-2"
            [src]="companyPanelIcon + 'Company-logo.svg'"
            *ngIf="companyPanelList.companyLogo == null"
          />
          <span id="company-name" class="col-10">{{ companyPanelList.companyName | titlecase }}</span>
        </div>
        <!-- companyName -->

        <!-- companyUrl -->
        <div
          class="company-panel-detail row justify-space-around align-items-center"
          *ngIf="companyPanelList.domain !== null"
        >
          <img class="col-2" [src]="companyPanelIcon + 'domain.svg'" />
          <span id="company-domain" (click)="openCompanyPanelUrl(companyPanelList.domain)" class="col-10">{{
            'www.' + companyPanelList.domain
          }}</span>
        </div>
        <!-- companyUrl -->

        <!-- companyDescription -->
        <div
          class="company-panel-detail row justify-space-around align-items-baseline"
          *ngIf="companyPanelList.companyDescription !== null"
        >
          <img class="col-2" [src]="companyPanelIcon + 'discription.svg'" />
          <span class="col-10"
            >{{ companyPanelList.companyDescription | slice : 0 : descriptionSlice }}
            <span id="show-more-description" (click)="companyShowMore()">
              {{ showFullDescription ? 'View more...' : ' View less...' }}</span
            >
          </span>
        </div>
        <!-- companyDescription -->

        <!-- companyLocation -->
        <div
          class="company-panel-detail row justify-space-around align-items-center"
          *ngIf="companyPanelList.location !== null"
        >
          <img class="col-2" [src]="companyPanelIcon + 'location.svg'" />
          <span class="col-10">{{ companyPanelList.location | titlecase }} </span>
        </div>
        <!-- companyLcoation -->

        <!-- employeeCount -->
        <div
          class="company-panel-detail row justify-space-around align-items-center"
          *ngIf="companyPanelList.employeeRange !== null"
        >
          <img class="col-2" [src]="companyPanelIcon + 'Employee-range.svg'" />
          <span class="col-10">{{ companyPanelList.employeeRange }} </span>
        </div>
        <!-- employeeCount -->

        <!-- companyIndustry -->
        <div
          class="company-panel-detail row justify-space-around align-items-center"
          *ngIf="companyPanelList.idustry !== null"
        >
          <img class="col-2" [src]="companyPanelIcon + 'industry.svg'" />
          <span class="col-10">{{ companyPanelList.industry | titlecase }} </span>
        </div>
        <!-- companyIndustry -->

        <!-- companyRevenue -->
        <div
          class="company-panel-detail row justify-space-around align-items-center"
          *ngIf="companyPanelList.revenue !== null"
        >
          <img class="col-2" [src]="companyPanelIcon + 'Revenue.svg'" />
          <span class="col-10">{{ companyPanelList.revenue }} </span>
        </div>
        <!-- companyRevenue -->

        <!-- companyFoundedYear -->
        <div
          class="company-panel-detail row justify-space-around align-items-center"
          *ngIf="companyPanelList.foundedYear !== null"
        >
          <img class="col-2" [src]="companyPanelIcon + 'Founded-year.svg'" />
          <span class="col-10">{{ companyPanelList.foundedYear }} </span>
        </div>
        <!-- companyFoundedYear -->

        <!-- socialIcons -->
        <div
          class="company-panel-detail row justify-space-around align-items-center"
          *ngIf="companyPanelList.linkedinURL !== null || companyPanelList.twitterUrl !== null"
        >
          <img class="col-2" [src]="companyPanelIcon + 'social-media.svg'" />
          <span class="col-10 d-flex company-social-media">
            <img
              *ngIf="companyPanelList.linkedinURL !== null"
              [src]="companyPanelIcon + 'linkedin.png'"
              (click)="openCompanyPanelUrl(companyPanelList.linkedinURL)"
            />
            <img
              class="ml-3"
              *ngIf="companyPanelList.twitterUrl !== null"
              [src]="companyPanelIcon + 'twitter.png'"
              (click)="openCompanyPanelUrl(companyPanelList.twitterUrl)"
            />
          </span>
        </div>
        <!-- socialIcons -->

         <!-- LinkedinFollowers -->
        <div
          class="company-panel-detail row justify-space-around align-items-center"
          *ngIf="companyPanelList.linkedinFollowers !== null"
        >
          <img class="col-2" [src]="companyPanelIcon + 'Linkedinfollowers.svg'" />
          <span class="col-10">{{ companyPanelList.linkedinFollowers }} </span>
        </div>
        <!-- LinkedinFollowers -->

      </div>
    </div>
  </div>
</mat-menu>
<!-- company panel menu ends -->
