<!-- Start Preloader -->
<div id="">
	<div class="square-block"></div>
</div>
<!-- Preloader End -->

<div class="container-fluid loginscreen">
	<app-loader *ngIf="showLoader == true"></app-loader>
	<app-success *ngIf="showMsg"></app-success>
	<app-error *ngIf="showError"></app-error>

	<div class="row">
		<div class="col-xs-12 col-sm-7 col-md-5 col-lg-4 authfy-panel-left">
			<!-- brand-logo start -->
			<div class="brand-logo text-center">
				<img src="assets/images/LakeB2B.png" width="150" alt="brand-logo" />
			</div>
			<!-- ./brand-logo -->
			<!-- authfy-login start -->
			<!--<div class="wrapper">
                    <div class="arrow-steps clearfix">
                        <div class="step current"> <span> Installed</span> </div>
                        <div class="step"> <span>Register</span> </div>
                        <div class="step"> <span> Verifiy</span> </div>
                      
                    </div>
                    <div class="nav clearfix">
                        <a href="#" class="prev">Previous</a>
                        <a href="#" class="next pull-right">Next</a>
                    </div>
                </div>-->

			<div class="authfy-login">
				<!-- panel-login start -->
				<div
					class="authfy-panel panel-login text-center"
					[ngClass]="{ active: currentScene == 'login' }"
				>
					<div class="authfy-heading">
						<h3 class="auth-title">Login to your account</h3>
						<p>
							Don’t have an account?
							<a class="lnk-toggler link" data-panel=".panel-signup" href="https://www.ampliz.com/signup"
								>Sign Up Free!</a
							>
						</p>

						<!-- <p>Don’t have an account? <a class="lnk-toggler link" data-panel=".panel-signup" (click)="showScreen('signup')">Sign Up Free!</a></p> -->
					</div>
					<div *ngIf="errmsg" class="alert alert-danger" role="alert">
						Email already registered,but email not yet verified, please verify the email
						<a href="#"><b>here</b></a>
					</div>

					<!-- social login buttons start -->
					<!-- <div class="row social-buttons">
                        <div class="col-xs-6 col-sm-6">
                            <a href="#" class="btn btn-lg btn-block btn-twitter">
                                <i class="fa fa-linkedin-square" aria-hidden="true"></i> &nbsp;Linkedin
                            </a>
                        </div>

                        <div class="col-xs-6 col-sm-6">
                            <a href="#" class="btn btn-lg btn-block btn-google">
                                <i class="fa fa-google" aria-hidden="true"></i>
                                &nbsp;Google
                            </a>
                        </div>
                    </div> -->
					<!-- ./social-buttons -->
					<!-- <div class="row loginOr">
                        <div class="col-xs-12 col-sm-12">
                            <span class="spanOr">or</span>
                        </div>
                    </div> -->
					<div class="row">
						<div class="col-xs-12 col-sm-12">
							<form name="loginForm" class="loginForm">
								<div class="form-group wrap-input">
									<input
										type="email"
										class="form-control email"
										[(ngModel)]="loginBody.email"
										name="username"
										placeholder="Email Address"
										[ngModelOptions]="{ standalone: true }"
									/>
									<span class="focus-input"></span>
								</div>
								<p class="error-message" *ngIf="emailError">Please Enter a Valid Email Address</p>
								<div class="form-group wrap-input">
									<div class="pwdMask">
										<input
											[type]="passwordShow == true ? 'text' : 'password'"
											class="form-control password"
											name="password"
											[(ngModel)]="loginBody.pwd"
											placeholder="Password"
											[ngModelOptions]="{ standalone: true }"
										/>
										<span class="focus-input"></span>
										<span
											(mouseenter)="showPassword(true)"
											(mouseleave)="showPassword(false)"
											class="fa fa-eye-slash pwd-toggle"
											[class.fa-eye]="passwordShow"
											[class.fa-eye-slash]="!passwordShow"
										></span>
									</div>
								</div>
								<p class="error-message" *ngIf="passwordError">Please Enter a Valid Password</p>
								<div class="row remember-row">
									<div class="col-xs-6 col-sm-6">
										<!-- <label class="checkbox text-left">
                                            <input type="checkbox" value="remember-me"><span class="label-text">Remember me</span>
                                        </label> -->
									</div>
									<div class="col-xs-6 col-sm-6">
										<p class="forgotPwd">
											<a class="lnk-toggler link" data-panel=".panel-forgot" (click)="showScreen('forgot')"
												>Forgot password?</a
											>
										</p>
									</div>
								</div>
								<!-- ./remember-row -->
								<div class="form-group">
									<button (click)="login()" class="btn btn-lg btn-new btn-block" type="submit">
										Login with email
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				<!-- ./panel-login -->

				<!-- panel-signup start -->
				<!-- <div
          class="authfy-panel panel-signup text-center"
          [ngClass]="{ active: currentScene == 'signup' }">
          <div class="row">
            <div class="col-xs-12 col-sm-12">
              <div class="authfy-heading">
                <h3 class="auth-title">Sign up for free!</h3>
                <p class="term-policy text-muted small fnt_bld">
                  Enter your email address, a password will be emailed to you!
                  Check your Spam, Junk and Promotion folder if you don't find
                  our email in your inbox!
                </p>
              </div>
              <form name="signupForm" class="signupForm">
                <div class="form-group wrap-input">
                  <input
                    type="email"
                    class="form-control"
                    name="username"
                    [(ngModel)]="signupBody.email"
                    placeholder="Email Address" />
                  <span class="focus-input"></span>
                </div> -->
				<!-- <div class="form-group wrap-input">
                                    <input type="text" class="form-control" name="fullname" placeholder="Full name">
                                    <span class="focus-input"></span>
                                </div>
                                <div class="form-group wrap-input">
                                    <div class="pwdMask">
                                        <input type="password" class="form-control" name="password" placeholder="Password">
                                        <span class="focus-input"></span>
                                        <span class="fa fa-eye-slash pwd-toggle"></span>
                                    </div>
                                </div> -->
				<!-- <div class="form-group">
                  <p class="term-policy text-muted small">
                    I agree to the
                    <a
                      (click)="openUrl('ampliz.com/privacy-policy ')"
                      class="link"
                      >privacy policy</a
                    >
                    and
                    <a
                      (click)="openUrl('ampliz.com/terms-conditions ')"
                      class="link"
                      >terms of service</a
                    >.
                  </p>
                </div>
                <div class="form-group">
                  <button
                    (click)="signup()"
                    class="btn btn-lg btn-new btn-block"
                    type="submit">
                    Sign up with email
                  </button>
                </div>
              </form>
              <a
                class="lnk-toggler link"
                data-panel=".panel-login"
                (click)="showScreen('login')"
                >Already have an account? Login</a
              >
            </div>
          </div>
        </div> -->
				<!-- ./panel-signup -->

				<!-- panel-forget start -->
				<div class="authfy-panel panel-forgot" [ngClass]="{ active: currentScene == 'forgot' }">
					<div class="row">
						<div class="col-xs-12 col-sm-12">
							<div class="authfy-heading">
								<h3 class="auth-title">Recover your password</h3>
								<p>
									Fill in your e-mail address below and we will send you an email with further instructions.
								</p>
							</div>
							<form name="forgetForm" class="forgetForm">
								<div class="form-group wrap-input">
									<input
										type="email"
										class="form-control"
										placeholder="Email Address"
										name="forgot-username"
										[(ngModel)]="forgotBody.email"
										[ngModelOptions]="{ standalone: true }"
									/>
									<span class="focus-input"></span>
								</div>
								<div class="form-group">
									<button
										(click)="forgot()"
										class="btn btn-lg btn-new btn-block"
										data-panel=".panel-signup"
										type="submit"
									>
										Recover your password
									</button>
								</div>
								<div class="form-group">
									<a class="lnk-toggler link" data-panel=".panel-login" (click)="showScreen('login')"
										>Already have an account? Login</a
									>
								</div>
								<div class="form-group">
									<a class="lnk-toggler link" data-panel=".panel-signup" href="https://www.ampliz.com/signup"
										>Don’t have an account? Sign up</a
									>
								</div>
							</form>
						</div>
					</div>
				</div>
				<!-- ./panel-forgot -->

				<!-- panel-invited-login -->
				<div class="authfy-panel panel-invited" [ngClass]="{ active: currentScene == 'invited' }">
					<div class="row">
						<div class="col-xs-12 col-sm-12">
							<div class="authfy-heading">
								<h3 class="auth-title">Create Account</h3>
								<p>
									Almost there! Now let’s set up your new Ampliz password. Make sure it is at least 10
									characters long and includes at least one number
								</p>
							</div>
							<form name="invitedForm" class="invitedForm">
								<div class="form-group wrap-input">
									<input
										type="email"
										disabled
										class="form-control"
										placeholder="Email Address"
										name="invited-username"
										[(ngModel)]="invitedBody.email"
										[ngModelOptions]="{ standalone: true }"
									/>
									<span class="focus-input"></span>
								</div>
								<div class="form-group wrap-input">
									<div class="pwdMask">
										<input
											[type]="invitedBody.show_pwd == true ? 'text' : 'password'"
											class="form-control"
											placeholder="Set password"
											name="invited-set-password"
											[(ngModel)]="invitedBody.pwd"
											[ngModelOptions]="{ standalone: true }"
										/>
										<span class="focus-input"></span>
										<span
											(mouseenter)="invitedBody.show_pwd = true"
											(mouseleave)="invitedBody.show_pwd = false"
											class="fa pwd-toggle"
											[class.fa-eye]="invitedBody.show_pwd"
											[class.fa-eye-slash]="!invitedBody.show_pwd"
										></span>
									</div>
								</div>
								<div class="form-group wrap-input">
									<div class="pwdMask">
										<input
											[type]="invitedBody.show_confirm_pwd == true ? 'text' : 'password'"
											class="form-control"
											placeholder="Confirm password"
											name="invited-confirm-password"
											[(ngModel)]="invitedBody.confirm_pwd"
											[ngModelOptions]="{ standalone: true }"
										/>
										<span class="focus-input"></span>
										<span
											(mouseenter)="invitedBody.show_confirm_pwd = true"
											(mouseleave)="invitedBody.show_confirm_pwd = false"
											class="fa pwd-toggle"
											[class.fa-eye]="invitedBody.show_confirm_pwd"
											[class.fa-eye-slash]="!invitedBody.show_confirm_pwd"
										></span>
									</div>
								</div>
								<p class="error-message" *ngIf="passwordError">Passwords did not match</p>
								<div class="form-group">
									<div class="confirm-policy">
										<span>
											<input
												type="checkbox"
												class="policy-checkbox"
												[(ngModel)]="invitedBody.policy"
												[ngModelOptions]="{ standalone: true }"
											/>
											I have read and agree with the
											<a href="https://www.ampliz.com/terms-conditions"> Terms and Conditions </a> and our
											<a href="https://www.ampliz.com/privacy-policy">Privacy Policy</a>
										</span>
									</div>
									<button
										(click)="signupInvited()"
										class="btn btn-lg btn-new btn-block"
										data-panel=".panel-signup"
										type="submit"
										[disabled]="showCreateAccountBtn ? false : true"
									>
										Create account
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				<!-- ./panel-invited-login -->
			</div>
			<!-- ./authfy-login -->
		</div>
		<!-- ./authfy-panel-left -->
		<div class="col-md-7 col-lg-8 authfy-panel-right hidden-xs hidden-sm">
			<div class="hero-heading">
				<div class="headline">
					<h3>Supercharge yourself with LakeB2B</h3>
					<!--<p>Access to the most powerfull tool in the entire industry.</p>-->
				</div>
			</div>
		</div>
	</div>
	<!-- ./row -->
</div>
<!-- ./container -->
<ng-template let-data #expiredInvitation>
	<button class="close-button" mat-dialog-close>
		<mat-icon class="close-icon">close</mat-icon>
	</button>
	<mat-dialog-content class="dialog-image" align="center">
		<img src="assets/images/expired-invitation-icon.png" width="50px" />
	</mat-dialog-content>
	<mat-dialog-content align="center">
		<h2 mat-dialog-title class="dialog-heading">{{ data.heading }}</h2>
		<p class="dialog-message">{{ data.message }}</p>
	</mat-dialog-content>
	<mat-dialog-actions align="center" class="dialog-btn">
		<button
			(click)="requestInvite()"
			class="btn btn-lg btn-new btn-block"
			type="submit"
			style="background: #6d08be !important"
		>
			{{ data.buttonText }}
		</button>
	</mat-dialog-actions>
</ng-template>
